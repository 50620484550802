import { ChevronLeft, ChevronRight } from "lucide-react";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import type { Shop, User } from "@/types";

interface Props {
  shop: Shop;
  user?: User | null;
}

const desktopImages = [
  "/img/finestep/finestepshoes-home-1.jpg",
  "/img/finestep/finestepshoes-home-2.jpg",
];

const mobileImages = [
  "/img/finestep/finestepshoes-mobile-1.jpg",
  "/img/finestep/finestepshoes-mobile-2.jpg",
  "/img/finestep/finestepshoes-mobile-3.jpg",
  "/img/finestep/finestepshoes-mobile-4.jpg",
  "/img/finestep/finestepshoes-mobile-5.jpg",
  "/img/finestep/finestepshoes-mobile-6.jpg",
  "/img/finestep/finestepshoes-mobile-7.jpg",
  "/img/finestep/finestepshoes-mobile-8.jpg",
];

const Hero = ({ shop, user }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [nextSlide, setNextSlide] = useState(1);
  const [transitioning, setTransitioning] = useState(false);
  const [documentWidth, setDocumentWidth] = useState(0);
  const [images, setImages] = useState(mobileImages);

  // handle resize
  useEffect(() => {
    const handleResize = () => {
      if (!window) return;
      setDocumentWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // handle on load
  useEffect(() => {
    if (!window) return;
    setDocumentWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    if (documentWidth > 425) {
      setImages(desktopImages);
    } else {
      setImages(mobileImages);
    }
  }, [documentWidth]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTransitioning(true);
      setTimeout(() => {
        setCurrentSlide(nextSlide);
        setNextSlide((nextSlide + 1) % images.length);
        setTransitioning(false);
      }, 1000);
    }, 5000);
    return () => clearInterval(timer);
  }, [nextSlide]);

  return (
    <section className="relative h-[80vh] overflow-hidden">
      {[currentSlide, nextSlide].map((slideIndex, index) => (
        <div
          key={slideIndex}
          className={`absolute inset-0 transition-opacity duration-1000 ${
            index === 0
              ? transitioning
                ? "opacity-0"
                : "opacity-100"
              : transitioning
                ? "opacity-100"
                : "opacity-0"
          }`}>
          <img
            src={images[slideIndex]}
            alt={`Hero background ${slideIndex + 1}`}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-kp-black/70 to-kp-black/30" />
        </div>
      ))}

      <div className="absolute inset-0 flex items-center justify-between px-4">
        <button
          onClick={() => {
            setTransitioning(true);
            setTimeout(() => {
              setCurrentSlide(
                (prev) => (prev - 1 + images.length) % images.length
              );
              setNextSlide(
                (prev) => (prev - 1 + images.length) % images.length
              );
              setTransitioning(false);
            }, 1000);
          }}
          className="hidden sm:block text-kp-white hover:text-kp-accent transition-colors"
          aria-label="Previous slide">
          <ChevronLeft size={48} />
        </button>
        <button
          onClick={() => {
            setTransitioning(true);
            setTimeout(() => {
              setCurrentSlide(nextSlide);
              setNextSlide((nextSlide + 1) % images.length);
              setTransitioning(false);
            }, 1000);
          }}
          className="hidden sm:block text-kp-white hover:text-kp-accent transition-colors"
          aria-label="Next slide">
          <ChevronRight size={48} />
        </button>
      </div>

      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 hidden sm:flex space-x-2">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => {
              setTransitioning(true);
              setTimeout(() => {
                setCurrentSlide(index);
                setNextSlide((index + 1) % images.length);
                setTransitioning(false);
              }, 1000);
            }}
            className={`w-3 h-3 rounded-full ${
              index === currentSlide ? "bg-kp-primary" : "bg-kp-white"
            }`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>

      <div className="relative container mx-auto px-4 h-full flex items-center pt-16 sm:pt-0">
        <div className="max-w-2xl text-kp-white">
          <h1 className="text-3xl sm:text-5xl md:text-6xl font-bold mb-4 sm:mb-6 drop-shadow-lg">
            FINE <span className="text-kp-primary">STEP</span> SHOES
          </h1>
          <h2 className="text-lg sm:text-xl mb-6 sm:mb-8 drop-shadow-lg">
            Feel the walk in every step you take.
          </h2>
          <motion.button
            className="px-6 sm:px-8 py-2 sm:py-3 bg-kp-accent text-kp-black rounded-full text-base sm:text-lg font-semibold hover:bg-kp-accent hover:text-kp-black transition-colors"
            whileTap={{ scale: 0.95 }}>
            View All
          </motion.button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
